import styles from "./banner.module.scss";
import Button from "../button/Button";
import clsx from "clsx";
import {Link, useParams} from "react-router-dom";
import {useState} from "react";
import ModalForm from "../modalForm/ModalForm";

const BANNER_TYPE = {
    mainBanner : {
        title : 'Автошкола в Минске "Империя Вождения"',
        text : 'Наша автошкола предлагает качественное обучение вождению, которое поможет Вам получить право управления транспортными средствами категорий "A", "C", "CE", "D" и с легкостью управлять ими на дороге.',
        whiteButtonText : 'Цены',
    },
    category_a : {
        title : 'Права категории "A"',
        text : 'Курс переподготовки предоставит любому желающему пройти обучение для получения навыков и права управления мотоциклом с любым объемом двигателя (подкатегория «А1» и категория «А»).',
        whiteButtonText : '400 р.',
    },
    category_c : {
        title : 'Права категории "C"',
        text : 'Курс переподготовки водителя МТС категории «В» на право управления МТС категории «С» (грузовые автомобили с технически допустимой общей массой более 3,5 тонн).',
        whiteButtonText : '650 р.',
    },
    category_ce : {
        title : 'Права категории "CE"',
        text : 'Курс переподготовки водителя МТС категории «С» на право управления составами транспортных средств категории «СЕ» (грузовой автомобиль с прицепом/полуприцепом массой более 750 кг).',
        whiteButtonText : '720 р.',
    },
    category_d_after_b : {
        title : 'Права категории "D"',
        text : 'Курс переподготовки водителя МТС категории «В» на право управления МТС категории «D» (автобусы с числом пассажирских мест 9 и более). Дополнительным бонусом для Вас станет возможность сдачи экзаменов и получения права управления МТС категории «С».\n',
        whiteButtonText : '900 р.',
    },
    category_d_after_c : {
        title : 'Права категории "D"',
        text : 'Курс переподготовки водителя МТС категории «С» на право управления МТС категории «D» (автобусы с числом пассажирских мест 9 и более).',
        whiteButtonText : '700 р.',
    },
    international_driver : {
        title : 'Водитель-международник',
        text : 'Специальный курс повышения квалификации водителей, выполняющих автомобильные перевозки грузов в международном сообщении с получением квалификационной карточки водителя-международника.',
        whiteButtonText : '120 р.',
    },
    additional_lessons : {
        title : 'Стажировка на фуре и тест-драйв',
        text : 'Услуга по проведению стажировочных занятий и тест-драйва на грузовом автопоезде (фуре) для кандидатов на должность водителей, осуществляющих перевозку грузов в международном сообщении.',
        whiteButtonText : 'от 100 р.',
    },
    individual_lessons : {
        title : 'Индивидуальные занятия',
        text : 'Дополнительная услуга по проведению занятий по вождению автомобиля. Любой тип транспортных средств – легковой автомобиль, грузовой автомобиль, автобус, грузовой автопоезд. Время и количество часов занятий выбираются индивидуально. ',
        whiteButtonText : 'от 40 р.',
    }
}

const Banner = ({type}) => {
    let { categoryName } = useParams();
    if (categoryName) {
        categoryName = categoryName.toLowerCase();
    }

    const [modalForm, setModalForm] = useState(false);
    const bannerInfo = BANNER_TYPE[categoryName ?? 'mainBanner'];


    const showForm = () => {
        document.body.style.overflow = "hidden";
        setModalForm(true)
    }

    return (
        <section className={clsx(styles.banner, styles[categoryName ?? 'mainBanner'])}>
            <div className={clsx(styles.container, styles.bannerContainer, styles[categoryName ?? 'mainBanner'])}>
                <h1 className={styles.bannerTitle}>{bannerInfo.title}</h1>
                <div className={styles.bannerText}>{bannerInfo.text}</div>
                <div className={styles.bannerButtons}>
                    <Button action={showForm} buttonType="buttonGold">Заказать звонок</Button>
                    {categoryName ?
                        <Button disabled={type === 'categoryBanner'} buttonType="buttonBlack">{bannerInfo.whiteButtonText}</Button>
                        :
                        <Link to={{pathname: '/', hash: '#services'}}>
                            <Button disabled={type === 'categoryBanner'} buttonType="buttonBlack">{bannerInfo.whiteButtonText}</Button>
                        </Link>
                    }
                </div>
            </div>
            <ModalForm active={modalForm} setActive={setModalForm} />
        </section>
    );
};

export default Banner;