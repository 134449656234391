import React from 'react';
import styles from './categoryInfo.module.scss'
import clsx from "clsx";
import {useParams} from "react-router-dom";
import {
    additionalLessons,
    CategoryA,
    CategoryC,
    CategoryCE,
    CategoryDB,
    CategoryDC,
    individualLessons,
    internationalDriver
} from "./Meta"

const CATEGORY_INFO = {
    category_a : {
        generalInfo : 'Учебная программа по переподготовке водителей МТС «В» на право управления МТС категории «А» включает 18 часов практических занятий на мотодроме. <br/>' +
            '<br/>' +
            'После успешной сдачи практического экзамена в ГАИ Вы получите право управления мотоциклом любого типа, в том числе: <br/>' +
            '- подкатегории «А1» (объем двигателя до 125 куб. см);<br/>' +
            '- категории «А» (объем двигателя более 125 куб. см).<br/>' +
            '<br/>' +
            'Мотоцикл для обучения: HORS F160 (162 куб. см) 2020 г. выпуска.',
        preparation : 'Срок обучения – 1 месяц.',
        documents : '- паспорт;<br/>' +
            '- водительское удостоверение;<br/>' +
            '- медицинская справка;<br/>' +
            '- 2 фото.',
        restrictions: '- с 18 лет;<br/>' +
            '- стаж не регламентируется.',
        meta: CategoryA,
    },
    category_c : {
        generalInfo : 'Для того, чтобы управлять грузовыми автомобилями с технически допустимой общей массой более 3,5 тонн, Вам необходимо иметь водительское удостоверение категории «С». В Республике Беларусь оно дает право управления любыми грузовыми автомобилями без ограничения предельной массы, а также право буксировки ими прицепа до 750 кг.<br/>' +
            '<br/>' +
            'В нашей автошколе Вы сможете пройти курс переподготовки водителя МТС категории «В» на право управления МТС категории «С». Практическое обучение на автодроме проходит на автомобиле ГАЗ 3309, вождение в реальных дорожных условиях – на автомобиле GAZ Next.<br/>' +
            '<br/>' +
            'На этих же автомобилях Вы будете сдавать квалификационный экзамен в ГАИ.',
        preparation : '- 1-1,5 месяца обучения;<br/>' +
            '- курс теоретической подготовки;<br/>' +
            '- практические занятия по вождению грузового автомобиля на автодроме;<br/>' +
            '- практические занятия по вождению грузового автомобиля в городских условиях;<br/>' +
            '- практические занятия по вождению грузового автомобиля по маршрутам ГАИ;<br/>' +
            '- предоставление автомобиля на экзамен в ГАИ в составе группы.',
        documents : '- паспорт;<br/>' +
            '- водительское удостоверение;<br/>' +
            '- медицинская справка;<br/>' +
            '- 2 фото.',
        restrictions: '- с 18 лет;<br/>' +
            '- стаж не регламентируется.',
        meta: CategoryC,
    },
    category_ce : {
        generalInfo : 'Для того, чтобы управлять грузовыми автомобилями с прицепами/полуприцепами массой более 750 кг, Вам необходимо иметь водительское удостоверение категории «СЕ». В Республике Беларусь оно дает право управления любыми грузовыми автомобилями с прицепами, полуприцепами любого типа и веса.<br/>' +
            '<br/>' +
            'В нашей автошколе Вы сможете пройти курс переподготовки водителя МТС категории «С» на право управления составами транспортных средств категории «СЕ». Практическое обучение на автодроме проходит на автомобиле ГАЗ 3309 с двухосным прицепом, вождение в реальных дорожных условиях – на автомобиле GAZ Next с двухосным прицепом.<br/>' +
            '<br/>' +
            'На этих же автомобилях Вы будете сдавать квалификационный экзамен в ГАИ.',
        preparation : '- 1-1,5 месяца обучения;<br/>' +
            '- курс теоретической подготовки;<br/>' +
            '- практические занятия по вождению грузового автомобиля c прицепом на автодроме;<br/>' +
            '- практические занятия по вождению грузового автомобиля с прицепом в городских условиях;<br/>' +
            '- практические занятия по вождению грузового автомобиля с прицепом по маршрутам ГАИ;<br/>' +
            '- предоставление автомобиля на экзамен в ГАИ в составе группы.',
        documents : '- паспорт;<br/>' +
            '- водительское удостоверение;<br/>' +
            '- медицинская справка;<br/>' +
            '- 2 фото.',
        restrictions: '- с 21 года;<br/>' +
            '- стаж по категории «В» или «С» не менее 2 лет.',
        meta: CategoryCE,
    },
    category_d_after_b : {
        generalInfo : 'Для того, чтобы управлять автобусами, Вам необходимо иметь водительское удостоверение категории «D». В Республике Беларусь оно дает право управления любыми автобусами с числом пассажирских мест 9 и более (в том числе, автобусами массой до 5 тонн – класс М2, автобусами массой более 5 тонн – класс М3), а также право буксировки ими прицепа до 750 кг.<br/>' +
            '<br/>' +
            'В нашей автошколе Вы сможете пройти курс переподготовки водителя МТС категории «В» на право управления МТС категории «D». Практическое обучение проходит на автобусе Ford Transit (18 пассажирских мест).<br/>' +
            '<br/>' +
            'На этом же автобусе Вы будете сдавать квалификационный экзамен в ГАИ.',
        preparation : '- 2 месяца обучения;<br/>' +
            '- курс теоретической подготовки;<br/>' +
            '- практические занятия по вождению автобуса на автодроме;<br/>' +
            '- практические занятия по вождению автобуса в городских условиях;<br/>' +
            '- практические занятия по вождению автобуса по маршрутам ГАИ;<br/>' +
            '- предоставление автобуса на экзамен в ГАИ в составе группы.<br/>' +
            '<br/>' +
            'Отличным бонусом для Вас станет возможность сдачи экзамена в ГАИ на категорию «С» (дополнительно учиться не требуется), Т.е. после прохождения обучения Вы сможете открыть и категорию «D» и категорию «С». Дополнительные занятия по вождению грузового автомобиля мы оказываем.',
        documents : '- паспорт;<br/>' +
            '- водительское удостоверение;<br/>' +
            '- медицинская справка;<br/>' +
            '- 2 фото.',
        restrictions: '- с 21 года;<br/>' +
            '- стаж по категории «В» не менее 2 лет.',
        meta: CategoryDB,
    },
    category_d_after_c : {
        generalInfo : 'Для того, чтобы управлять автобусами, Вам необходимо иметь водительское удостоверение категории «D». В Республике Беларусь оно дает право управления любыми автобусами с числом пассажирских мест 9 и более (в том числе, автобусами массой до 5 тонн – класс М2, автобусами массой более 5 тонн – класс М3), а также право буксировки ими прицепа до 750 кг.<br/>' +
            '<br/>' +
            'В нашей автошколе Вы сможете пройти курс переподготовки водителя МТС категории «С» на право управления МТС категории «D». Практическое обучение проходит на автобусе Ford Transit (18 пассажирских мест).<br/>' +
            '<br/>' +
            'На этом же автобусе Вы будете сдавать квалификационный экзамен в ГАИ.',
        preparation : '- 1,5 - 2 месяца обучения;<br/>' +
            '- курс теоретической подготовки;<br/>' +
            '- практические занятия по вождению автобуса на автодроме;<br/>' +
            '- практические занятия по вождению автобуса в городских условиях;<br/>' +
            '- практические занятия по вождению автобуса по маршрутам ГАИ;<br/>' +
            '- предоставление автобуса на экзамен в ГАИ в составе группы.',
        documents : '- паспорт;<br/>' +
            '- водительское удостоверение;<br/>' +
            '- медицинская справка;<br/>' +
            '- 2 фото.',
        restrictions: '- с 21 года;<br/>' +
            '- стаж по категории «В» или «С» не менее 2 лет.',
        meta: CategoryDC,
    },
    international_driver : {
        generalInfo : 'Для получения квалификационной карточки водителя, который выполняет международные автомобильные перевозки грузов, необходимо пройти обучение на профессиональную компетентность и сдать теоретический экзамен.<br/>' +
            '<br/>' +
            'Срок действия карточки водителя-международника – 5 лет.',
        preparation : '- правил автомобильных перевозок грузов;<br/>' +
            '- основ логистики;<br/>' +
            '- режима труда и отдыха;<br/>' +
            '- правил оформления транспортных документов;<br/>' +
            '- охраны труда и многое другое.',
        meta: internationalDriver,
    },
    additional_lessons : {
        generalInfo : 'Автошкола «Империя Вождения» оказывает услугу по проведению стажировочных занятий и тест-драйва кандидатов на должность водителя, осуществляющего перевозку грузов в международном сообщении.<br/>' +
            '<br/>' +
            'Практические занятия (с проведением инструктажа по управлению автопоездом) и тест-драйв проводятся на седельном тягаче Volvo FH12 с полуприцепом на автодроме с асфальтобетонным покрытием.<br/>' +
            '<br/>' +
            'Занятия и тест-драйв проводятся специалистами, имеющими опыт международных перевозок и преподавания в автошколе.<br/>',
        preparation : '- водители автопоездов (категория «СЕ») для трудоустройства в европейские транспортные компании (наши партнеры –компании ООО «Фортисплюс» и Girteka Logistics);<br/>' +
            '- водители, проходящие стажировку для выполнения внутренних перевозок;<br/>' +
            '- водители, начинающие работу в сфере грузоперевозок, для повышения своего профессионального уровня.',
        documents : '',
        restrictions: '',
        meta: additionalLessons,
    },
    individual_lessons : {
        generalInfo : 'Для тех, кто желает повысить свой профессиональный уровень, автошкола ООО «Империя Вождения» готова предоставить дополнительные занятия по вождению автомобиля. Они могут проводиться нами на любом типе автомобилей – на легковом, грузовом, автобусе, а также на полноценной фуре.',
        preparation : 'Данной услугой могут воспользоваться как лица, имеющие водительское удостоверение, так и те, кто готовится к сдаче экзаменов.<br/>' +
            '<br/>' +
            'Дополнительные занятия проводятся также на легковом автомобиле с автоматической коробкой передач.<br/>' +
            '<br/>' +
            'Для проведения дополнительных занятий мы предложим удобное для Вас время.',
        documents : '',
        restrictions: '',
        meta: individualLessons,
    }
}

const CategoryInfo = () => {
    let { categoryName} = useParams();
    categoryName = categoryName.toLowerCase()

    return (
        <section className={styles.categoryInfo}>

            {CATEGORY_INFO[categoryName].meta}

            <div className={clsx(styles.container, styles.categoryInfoContainer)}>
                <div className={styles.categoryInfoItem}>
                    <div className={styles.categoryInfoTitle}>Общая информация</div>
                    <div className={styles.categoryInfoText} dangerouslySetInnerHTML={{__html: CATEGORY_INFO[categoryName].generalInfo}}/>
                    {CATEGORY_INFO[categoryName].documents &&
                        <>
                            <div className={styles.categoryInfoTitle}>Необходимые документы для оформления:</div>
                            <div className={styles.categoryInfoText} dangerouslySetInnerHTML={{__html: CATEGORY_INFO[categoryName].documents}}/>
                        </>
                    }
                </div>
                <div className={styles.categoryInfoItem}>
                    <div className={styles.categoryInfoTitle}>{categoryName === 'international_Driver' ? 'Программа обучающего курса предусматривает изучение:' : categoryName === 'additional_Lessons' ? 'Кому нужна данная услуга:' : categoryName === 'individual_Lessons' ? 'Дополнительная информация' : 'Программа переподготовки включает в себя:'}</div>
                    <div className={styles.categoryInfoText} dangerouslySetInnerHTML={{__html: CATEGORY_INFO[categoryName].preparation}}/>
                    {CATEGORY_INFO[categoryName].restrictions &&
                        <>
                            <div className={styles.categoryInfoTitle}>Возрастные ограничения и стаж:</div>
                            <div className={styles.categoryInfoText} dangerouslySetInnerHTML={{__html: CATEGORY_INFO[categoryName].restrictions}}/>
                        </>
                    }
                </div>
            </div>
        </section>
    );
};

export default CategoryInfo;