import React from 'react';
import styles from "./navigation.module.scss";
import {Link, useLocation, useParams} from "react-router-dom";
import clsx from "clsx";

let LINKS =[
    {id: 1, title: 'Главная', href: '/', hash: ''},
    {id: 2, title: 'Услуги', href: '/', hash: '#services'},
    {id: 3, title: 'О нас', href: '/', hash: '#about'},
    {id: 4, title: 'Акции', href: '/promotions', hash: ''},
    {id: 5, title: 'Новости', href: '/news/allnews', hash: ''},
    {id: 6, title: 'Контакты', href: '/contacts', hash: ''},
];

const Navigation = (props) => {
    const { categoryName } = useParams();
    const location = useLocation();

    LINKS[1].href = categoryName ? location.pathname : '/';

    return (
        <nav className={clsx(styles.navigation, props.classname)}>
            {LINKS.map((link) =>
                <Link to={{pathname: link.href, hash: link.hash}} key={link.id}>{link.title}</Link>
            )}
        </nav>
    );
};

export default Navigation;